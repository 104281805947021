import React from "react";

const HomeContent = () => (
    <div className="site-main">
        <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">
                <article id="post-4" className="post-4 page type-page status-publish hentry">
                    <header className="entry-header">
                        <h1 className="entry-title">Home</h1>
                    </header>
                    <div className="entry-content">
                        <h3 className="mc-toc-title" style={{ textAlign: "center" }}><span style={{ fontFamily: "georgia, palatino, serif", fontSize: "36px" }}><strong style={{ lineHeight: "1.7" }}>Welcome to Our Father&#8217;s Library</strong></span></h3>
                        <p style={{ textAlign: "center" }}><span className="s1" style={{ fontSize: "16px", fontFamily: "georgia, palatino, serif" }}>We are a non-profit, non-denominational Christian lending library that is open to the public. It was established on April 18, 1983. The ministry was founded in obedience to Jesus&#8217; command in Mark 16:15: &#8220;Go into all the world and preach the Gospel to every creature.&#8221;</span></p>
                        <p><span style={{ fontSize: "16px", fontFamily: "georgia, palatino, serif" }}>The library consists of approximately 20,000 items. We have a variety of books, audiobooks on&nbsp;cassette&nbsp;and CD, movies, and teaching sets.</span></p>
                        <p><span style={{ fontSize: "16px", fontFamily: "georgia, palatino, serif" }}>The ministry is operated solely by donations of materials and money. It is kept open daily by volunteer help.</span></p>
                        <p><span style={{ fontSize: "16px", fontFamily: "georgia, palatino, serif" }}>We praise God for his continual faithfulness towards this ministry, and the many people and organizations that support this ministry with their time and gifts.</span></p>
                        <p><span style={{ fontSize: "16px", fontFamily: "georgia, palatino, serif" }}>The library has Christian books and movies for all ages. The only charge is for videos ($1 weekly rental for DVDs and $2 weekly rental for Group Study sets.) We are open to the public Monday through Saturday. We are closed on Sundays and holidays</span></p>
                        <h3 style={{ textAlign: "center" }}><span style={{ fontSize: "20px", fontFamily: "georgia, palatino, serif" }}><strong>Our Father’s Library</strong></span></h3>
                        <h3 style={{ textAlign: "center" }}><span style={{ fontSize: "20px", fontFamily: "georgia, palatino, serif" }}><strong>36 W. 2nd Street, Peru, IN 46970</strong></span></h3>
                        <h3 style={{ textAlign: "center" }}><span style={{ fontSize: "20px", fontFamily: "georgia, palatino, serif" }}><strong>Phone# 765-473-9095</strong></span></h3>
                        <h3 style={{ textAlign: "center" }}><span style={{ fontSize: "20px", fontFamily: "georgia, palatino, serif" }}>E-Mail:&nbsp;<a href="mailto:ourfatherslib@sbcglobal.net">ourfatherslib@sbcglobal.net</a></span></h3>
                        <h3 className="null" style={{ textAlign: "center" }}>Our&nbsp;new hours will be:</h3>
                        <p style={{ textAlign: "center" }}><span style={{ fontSize: "20px" }}>Mondays&nbsp; 10 AM to 5 PM</span><br />
                            <span style={{ fontSize: "20px" }}>Wednesdays&nbsp; &nbsp;10 AM to 5 PM</span><br />
                            <span style={{ fontSize: "20px" }}>Fridays&nbsp; &nbsp;10 AM to 5 PM</span>
                        </p>
                    </div>
                    <footer className="entry-meta">
                    </footer>
                </article>
            </div>
        </div>
    </div>
);

export default HomeContent;