import React from "react";

class ContactUsContent extends React.Component {

    componentDidMount() {
        window.FB.XFBML.parse();
    }

    render() {
        const textAlign = "text-align: center;";
        return (
            <div className="site-main">
                <div id="primary" className="content-area">
                    <div id="content" className="site-content" role="main">
                        <header className="entry-header">
                            <h1 className="entry-title">Contact Us</h1>
                        </header>
                        <div className="entry-content">
                            <iframe loading="lazy" src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=+&amp;q=36+W.+2nd+Street,+Peru,+IN+46970&amp;ie=UTF8&amp;hq=&amp;hnear=36+W+2nd+St,+Peru,+Indiana+46970&amp;ll=40.75167,-86.069121&amp;spn=0.002068,0.004292&amp;t=m&amp;z=14&amp;iwloc=r1&amp;output=embed" width="425" height="350" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe>
                            <h3 style={{ textAlign }}>
                                Our Father’s Library<br />
                                36 W. 2nd Street, Peru, IN 46970<br />
                                Phone# 765-473-9095<br />
                                E-mail: ourfatherslib@sbcglobal.net
                            </h3>
                            <h4 style={{ textAlign }}>
                                Our new hours will be:
                            </h4>
                            <h5>
                                Mondays:  10 AM to 5 PM<br />
                                Wednesdays:   10 AM to 5 PM<br />
                                Fridays:   10 AM to 5 PM
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactUsContent;