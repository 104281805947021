import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// Reference: https://jasonwatmore.com/post/2020/10/28/react-facebook-how-to-use-the-facebook-sdk-in-a-react-app
//            https://developers.facebook.com/docs/javascript/reference/v10.0

// https://developers.facebook.com/apps/926442384790368/add/
// https://developers.facebook.com/docs/plugins/page-plugin/

const facebookAppId = '926442384790368';

export function initFacebookSdk() {
  console.log('a');

  return new Promise(resolve => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      console.log('b');
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v8.0'
      });

      resolve();
    };

    // window.FB.getLoginStatus(({ authResponse }) => {
    //   resolve();
    // }

    // load facebook sdk script
    (function (d, s, id) {
      console.log('c', d, s, id);
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      console.log('cc');
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  });
}

const startApp = () => {
  console.log('d');
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// wait for facebook sdk before startup
initFacebookSdk().then(startApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
