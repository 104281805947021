import React from "react";

const Footer = () => (
    <footer id="colophon" className="site-footer" role="contentinfo">
        <div id="secondary" className="sidebar-container" role="complementary">
            <div className="widget-area">
                <aside id="search-2" className="widget widget_search">
                    <form role="search" method="get" className="search-form" action="http://www.ourfatherslibrary.com/">
                        <label>
                            <span className="screen-reader-text">Search for:</span>
                            <input type="search" className="search-field" placeholder="Search &hellip;" value="" name="s" />
                        </label>
                        <input type="submit" className="search-submit" value="Search" />
                    </form>
                </aside>
            </div>
        </div>
        <div className="site-info">
            Copyright &copy; 2021 Our Fathers Library
        </div>
    </footer>
);

export default Footer;