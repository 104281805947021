import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";

const testText = 'This is a test <br />Another test';

const SupportContent = () => {

    const [supportList, setSupportList] = useState(null);

    useEffect(() => {
        // Fetch the needs from the API at https://8v28f2jvtg.execute-api.us-east-1.amazonaws.com/Prod/needs/
        fetch('https://8v28f2jvtg.execute-api.us-east-1.amazonaws.com/Prod/support/')
            .then((response) => response.json())
            .then((data => {
                console.log(data);
                // Sort on the order property
                data.support.sort((a, b) => (a.order > b.order) ? 1 : -1);

                setSupportList(data.support.map((s, i) => { return (<li key={i} className='li2'><span className="s1">{Parser(s.value)}</span></li>) }))
            }))
    }, []);

    return (
        <div className="site-main">
            <div id="primary" className="content-area">
                <div id="content" className="site-content" role="main">
                    <header className="entry-header">
                        <h1 className="entry-title">Support Library</h1>
                    </header>
                    <div className="entry-content">

                        <p style={{ "textAlign": "center" }}>
                            <strong>
                                <span style={{ "fontSize": "24px" }}>
                                    A few ways to support Our Father's Library!
                                </span>
                            </strong>
                        </p>
                        <ol className="p1">
                            {supportList}
                        </ol>
                        <br />
                        Thank you for your support!
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SupportContent;