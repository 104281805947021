import React from "react";
import './BlockLibraryTheme.css';
import './BlockLibraryStyle.css';
//import './Genericons.css';
import './PDFViewerStyle.css';
import './TwentyThirteenStyle.css';
import './TwentyThirteenBlocks.css';

import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import Admin from "./components/Admin";
import Header from "./components/Header";
import HomeContent from "./components/HomeContent";
import ContactUsContent from "./components/ContactUsContent";
import NewBooksContent from "./components/NewBooksContent";
import NeedsContent from "./components/NeedsContent";
import SupportContent from "./components/SupportContent";
import ComingSoonContent from "./components/ComingSoonContent";
import NewsletterSignUpContent from "./components/NewsletterSignUpContent";
import NewsletterPastIssuesContent from "./components/NewsletterPastIssuesContent";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div className="hfeed site">
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact>
            <HomeContent />
          </Route>
          <Route path="/new-books">
            <NewBooksContent />
          </Route>
          <Route path="/needs">
            <NeedsContent />
          </Route>
          <Route path="/support-library">
            <SupportContent />
          </Route>
          <Route path="/contact-us">
            <ContactUsContent />
          </Route>
          <Route path="/online-catalog">
            <ComingSoonContent />
          </Route>
          <Route path="/new-books">
            <ComingSoonContent />
          </Route>
          <Route path="/newsletter-sign-up">
            <NewsletterSignUpContent />
          </Route>
          <Route path="/newsletters">
            <NewsletterPastIssuesContent />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
