import React, { useState } from "react";
import * as AWS from 'aws-sdk/global';
// import {
//     CognitoUserPool,
//     CognitoUserAttribute,
//     CognitoUser,
// } from 'amazon-cognito-identity-js';

// https://stackoverflow.com/questions/37442973/cognito-user-pool-how-to-refresh-access-token-using-refresh-token


// Modules, e.g. Webpack:
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

var poolData = {
    UserPoolId: 'us-east-1_76B5FDfbP', // Your user pool id here
    ClientId: '54nkgi3ga0ej8r31kn6lqhj4r', // Your client id here
};

var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

const getCurrentJwt = async (cb) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        cognitoUser.getSession((err, data) => {
            if (err) {
                // Prompt the user to reauthenticate by hand...
                alert(err);
            } else {
                const cognitoUserSession = data;
                cb(cognitoUserSession.getAccessToken().jwtToken);
            }
        });
    } else {
        alert('please login again');
    }
}

const updateJwt = () => {
    getCurrentJwt((jwt) => console.log(jwt))
}

const signOut = () => {
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.signOut();
    alert('User Signed Out');
}

const loginUser = (userid, password, setJwt) => {
    var authenticationData = {
        // Username: 'testapi.jwhance.com',
        // Password: '12Qwasxd#',
        UserName: userid,
        Password: password
    };

    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
        authenticationData
    );

    var userData = {
        //Username: 'testapi.jwhance.com',
        Username: userid,
        Pool: userPool,
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
            var accessToken = result.getAccessToken().getJwtToken();
            console.log('accessToken:', accessToken);
            setJwt(accessToken);

            // var refToken = result.getRefreshToken().token;
            // console.log('refreshToken:', refToken);

            //POTENTIAL: Region needs to be set if not already set previously elsewhere.
            AWS.config.region = 'us-east-1';

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'us-east-1:33d00cf4-e2ba-47ef-974b-1cbe2b5bfcb7', // your identity pool id here
                Logins: {
                    // Change the key below according to the specific region your user pool is in.
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_76B5FDfbP': result
                        .getIdToken()
                        .getJwtToken(),
                },
            });

            //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
            AWS.config.credentials.refresh(error => {
                if (error) {
                    console.error(error);
                } else {
                    // Instantiate aws sdk service objects now that the credentials have been updated.
                    // example: var s3 = new AWS.S3();
                    console.log('Successfully logged in!');
                }
            });
        },

        onFailure: function (err) {
            alert(err.message || JSON.stringify(err));
        },
    });

}

const Admin = () => {
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [jwt, setJwt] = useState();

    return (
        <>
            Admin Page
            <hr />
            <label htmlFor="userid">User Id:</label>
            <input type='text' id='userid' name="userid" value={userId} onChange={(e) => setUserId(e.target.value)} />
            <br />
            <label htmlFor="password">Password:</label>
            <input type="password" id='password' name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <br />
            <button type="button" id="login" name="login" value="Login" onClick={() => loginUser(userId, password, setJwt)} >Login</button>
            <button type="button" id="JWT" name="JWT" onClick={() => updateJwt()}>JWT</button>
            <button type="button" id="SignOut" name="SignOut" onClick={() => signOut()}>Sign Out</button>
        </>
    )
};

export default Admin;