import React, { useState, useEffect } from "react";

const NeedsContent = () => {

    const [needsList, setNeedsList] = useState(null);

    useEffect(() => {
        // Fetch the needs from the API at https://8v28f2jvtg.execute-api.us-east-1.amazonaws.com/Prod/needs/
        // https://dev.to/olenadrugalya/ways-of-getting-data-from-api-in-react-2kpf
        fetch('https://8v28f2jvtg.execute-api.us-east-1.amazonaws.com/Prod/needs/')
            .then((response) => response.json())
            .then((data => {
                // Sort on the order property
                data.needs.sort((a, b) => (a.order > b.order) ? 1 : -1);

                setNeedsList(data.needs.map(need => { return (<li className='li2'>{need.value}</li>) }));
            }))
    }, []);

    return (
        <div className="site-main">
            <div id="primary" className="content-area">
                <div id="content" className="site-content" role="main">
                    <header className="entry-header">
                        <h1 className="entry-title">Needs</h1>
                    </header>
                    <div className="entry-content">

                        <p style={{ "textAlign": "center" }}>
                            <strong>
                                <span style={{ "fontSize": "24px" }}>
                                    It's the little things that add up in a big way
                                </span>
                            </strong>
                        </p>
                        <p className="p1">
                            <span className="s1" style={{ "fontSize": "20px" }}>
                                We need the following items:
                            </span>
                        </p>
                        <ol className="p1">
                            {needsList}
                        </ol>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default NeedsContent;