import React from "react";

const NewsletterSignUpContent = () => {
    return (
        <div className="site-main">
            <div id="primary" className="content-area">
                <div id="content" className="site-content" role="main">
                    <header className="entry-header">
                        <h1 className="entry-title">Newsletter Sign Up</h1>
                    </header>
                    <div className="entry-content">
                        <p style={{ "textAlign": "left" }}>
                            <strong>
                                <span style={{ "fontSize": "24px" }}>
                                    <a href="https://ourfatherslibrary.us10.list-manage.com/subscribe?u=ac67445310534f9531810b74d&id=9d21efec20" target="_blank" rel="noopener noreferrer">Subscribe to our newsletter</a>
                                </span>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default NewsletterSignUpContent;