import React from "react";

class NewBooksContent extends React.Component {

    componentDidMount() {
        window.FB.XFBML.parse();
    }

    render() {
        return (
            <div className="site-main">
                <div id="primary" className="content-area">
                    <div id="content" className="site-content" role="main">
                        <header className="entry-header">
                            <h1 className="entry-title">New Books</h1>
                        </header>
                        <div className="entry-content">
                            Coming soon!
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewBooksContent;