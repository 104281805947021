import React from "react";
import { NavLink } from "react-router-dom";

const activeStyle = { color: "#F15B2A" };

class Header extends React.Component {
    componentDidMount() {
        console.log('DID MOUNT!!');
        window.FB.XFBML.parse();
    }
    componentDidUpdate() {
        console.log('DID UPDATE!!');
        window.FB.XFBML.parse();
    }

    render() {
        return (
            <header id="masthead" className="site-header" role="banner">
                <a className="home-link" href="http://www.ourfatherslibrary.com/" title="Our Fathers Library" rel="home">
                    <h1 className="site-title">Our Fathers Library</h1>
                    <h2 className="site-description">Christian Books and Tapes</h2>
                </a>
                <div id="navbar" className="navbar">
                    <nav id="site-navigation" className="navigation main-navigation" role="navigation">
                        <button className="menu-toggle">Menu</button>
                        <a className="screen-reader-text skip-link" href="#content" title="Skip to content">Skip to content</a>
                        <div className="menu-main-menu-container">
                            <ul id="primary-menu" className="nav-menu">
                                <li id="menu-item-203" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-4 current_page_item menu-item-203">
                                    <NavLink to="/" activeStyle={activeStyle} exact>Home</NavLink>
                                </li>
                                <li id="menu-item-401" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-401">
                                    <NavLink to="/books" activeStyle={activeStyle} >Books</NavLink>
                                    <ul className="sub-menu">
                                        <li id="menu-item-403" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-403">
                                            <NavLink to="/new-books" activeStyle={activeStyle} >New Books</NavLink>
                                        </li>
                                        <li id="menu-item-133" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-133">
                                            <NavLink to="/online-catalog" activeStyle={activeStyle} >Online Catalog</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li id="menu-item-411" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-411">
                                    <NavLink to="/newsletter" activeStyle={activeStyle} >Newsletter</NavLink>
                                    <ul className="sub-menu">
                                        <li id="menu-item-412" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-412">
                                            <NavLink to="/newsletter-sign-up" activeStyle={activeStyle} >Newsletter Sign Up</NavLink>
                                        </li>
                                        <li id="menu-item-415" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-415">
                                            <NavLink to="/newsletters" activeStyle={activeStyle} >OFL Newsletters</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li id="menu-item-204" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-204">
                                    <NavLink to="/history" activeStyle={activeStyle} >History</NavLink>
                                    <ul className="sub-menu">
                                        <li id="menu-item-429" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-429">
                                            <NavLink to="/our-history" activeStyle={activeStyle} >Our History</NavLink>
                                        </li>
                                        <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-205">
                                            <NavLink to="/statement-of-faith" activeStyle={activeStyle} >Statement of Faith</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li id="menu-item-439" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-439">
                                    <NavLink to="/ways-to-support" activeStyle={activeStyle} >Ways to Support</NavLink>
                                    <ul className="sub-menu">
                                        <li id="menu-item-441" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-441">
                                            <NavLink to="/needs" activeStyle={activeStyle} >Needs</NavLink>
                                        </li>
                                        <li id="menu-item-440" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-440">
                                            <NavLink to="/support-library" activeStyle={activeStyle} >Support Library</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li id="menu-item-103" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-103">
                                    <NavLink to="/contact-us" activeStyle={activeStyle} >Contact Us</NavLink>
                                </li>
                            </ul>
                        </div>
                        <form role="search" method="get" className="search-form">
                            <label>
                                <span className="screen-reader-text">Search for:</span>
                                <input type="search" className="search-field" placeholder="Search &hellip;" value="" name="s" />
                            </label>
                            <input type="submit" className="search-submit" value="Search" onClick={console.log('I will do something when I grow up!')} />
                        </form>
                    </nav>
                    <div id="comments" className="comments-area">
                        <div id="tertiary" className="sidebar-container site-main" role="complementary">
                            <div className="sidebar-inner">
                                <div className="widget-area">
                                    <aside id="custom_html-5" className="widget_text widget widget_custom_html">
                                        <div className="textwidget custom-html-widget">
                                            <div className="fb-page" data-href="https://www.facebook.com/OFL9095" data-tabs="timeline" data-width="" data-height=""
                                                data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                                                <blockquote cite="https://www.facebook.com/OFL9095" className="fb-xfbml-parse-ignore">
                                                    <a href="https://www.facebook.com/OFL9095">Our Father&#039;s Library, Inc.</a>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;