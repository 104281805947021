import React from "react";

const ComingSoonContent = () => {
    return (
        <div className="site-main">
            <div id="primary" className="content-area">
                <div id="content" className="site-content" role="main">
                    <header className="entry-header">
                        <h1 className="entry-title">Coming Soon!</h1>
                    </header>
                    <div className="entry-content">
                        <p style={{ "textAlign": "center" }}>
                            <strong>
                                <span style={{ "fontSize": "24px" }}>
                                    Please check back later.
                                </span>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ComingSoonContent;